@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply cursor-pointer hover:text-default-blue
  }

  .card {
    @apply rounded-2xl bg-bg-accent;
  }

  .field {
    @apply basefont-t6 flex items-center gap-2;
  }

  input[type='checkbox'] {
    @apply bg-bg rounded;
    height: 20px;
    width: 20px;

    /* https://moderncss.dev/pure-css-custom-checkbox-style/ */
    appearance: none;
    margin: 0;
    font: inherit;
    color: currentColor;
    box-shadow: 0px 1px 3px 0px #0000001A inset;

    display: grid;
    place-content: center;
  }

  input[type='checkbox']::before {
    content: "";
    height: 20px;
    width: 20px;
  }


  input[type="checkbox"]:checked {
    @apply bg-default-blue
  }

  input[type='radio'] {
    @apply bg-bg rounded-full;
    height: 20px;
    width: 20px;

    /* https://moderncss.dev/pure-css-custom-styled-radio-buttons/ */
    appearance: none;
    margin: 0;
    font: inherit;
    color: currentColor;
    box-shadow: 0px 1px 3px 0px #0000001A inset;

    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    @apply shadow-[inset_1em_1em] shadow-default-blue;
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;

  }

  input[type="radio"]:checked {
    @apply border-default-blue border-2 bg-white
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
}

@layer utilities {
  .basefont-t1 {
    @apply font-extrabold text-base leading-6;
    letter-spacing: calc(0.02 * theme('fontSize.base'));
  }

  .basefont-t2 {
    @apply font-bold text-base leading-6;
    letter-spacing: calc(0.02 * theme('fontSize.base'));
  }

  .basefont-t3 {
    @apply font-normal text-base;
    letter-spacing: calc(0.02 * theme('fontSize.sm'));
  }

  .basefont-t4 {
    @apply font-bold text-sm leading-[21px];
    letter-spacing: calc(0.02 * theme('fontSize.sm'));
  }

  .basefont-t5 {
    @apply font-semibold text-sm leading-[21px];
    letter-spacing: calc(0.02 * theme('fontSize.sm'));
  }

  .basefont-t6 {
    @apply font-normal text-sm leading-[21px];
    letter-spacing: calc(0.02 * theme('fontSize.sm'));
  }

  .basefont-b1 {
    @apply font-semibold text-base leading-6;
    letter-spacing: calc(0.02 * theme('fontSize.base'));
  }

  .basefont-d1 {
    @apply font-semibold text-xs leading-[18px];
    letter-spacing: calc(0.02 * theme('fontSize.xs'));
  }

  .basefont-d2 {
    @apply font-normal text-xs leading-[18px];
    letter-spacing: calc(0.02 * theme('fontSize.xs'));
  }

  .basefont-h1 {
    @apply font-bold text-[32px] leading-[41.6px];
    letter-spacing: calc(0.02 * 32px);
  }

  .basefont-h2 {
    @apply font-bold text-[28px] leading-[26.4px];
    letter-spacing: calc(0.02 * 28px);
  }

  .basefont-h3 {
    @apply font-bold text-[24px] leading-[31.2px];
    letter-spacing: calc(0.02 * 24px);
  }

  .basefont-h4 {
    @apply font-bold text-[22px] leading-[28.6px];
    letter-spacing: calc(0.02 * 22px);
  }

  .basefont-h5 {
    @apply font-extrabold text-[18px] leading-[23.4px];
    letter-spacing: calc(0.02 * 18px);
  }

  .basefont-b2 {
    @apply font-normal text-base;
    letter-spacing: calc(0.02 * theme('fontSize.base'));
  }


  .text-wrap {
    text-wrap: wrap
  }

  .text-nowrap {
    text-wrap: nowrap
  }

  .text-balance {
    text-wrap: balance
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .darken {
    filter: brightness(93%);
  }
}

.personal-model ::-webkit-scrollbar {
  display: none;
}

.personal-model {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: auto;

}

.personal-model .mantine-Modal-content {
  border-radius: 30px;
  min-width: 464px;
  height: auto;
  gap: 8px;

}

.personal-model .mantine-Modal-title {
  color: #000000;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: bold;
  padding-left:10px;
  padding-top:10px

}

.personal-model .mantine-InputWrapper-label {
  color: #000000;
  font-family: Inter, sans-serif;
  font-size: 14px;
}

.personal-model .mantine-Input-input {
  border: 2px solid #E0E0E0;
  min-width: 400px;
  border-radius: 12px;
  color: #506176;
  font-family: Inter, sans-serif;
  font-size: 14px;
  height: 6vh !important;
  padding: 12px !important;
}

.personal-model .mantine-Button-root {
  width: 400px;
  height: 48px !important;
  border-radius: 12px;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.personal-model .dropzone {
  width: 410px;
  font: Inter;
  font-weight: 600;
  font-size: 14px;
  color: #8D96A0;
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid #E0E0E0;
  border-radius: 12px;
  background-color: #FFFFFF;

  &[data-accept] {
    color: var(--mantine-color-white);
    background-color: var(--mantine-color-blue-6);
  }

  &[data-reject] {
    color: var(--mantine-color-white);
    background-color: var(--mantine-color-red-6);
  }
}






