

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0;
}

.user-info span {
  display: block;
}

.options {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--mantine-font-size-sm);
  color: #506176;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
  font-weight: 500;

  &:hover {
    background-color: rgba(255, 255, 255, 0.96);
    color: #000000;

    & .optionsIcon {
      color: #000000;
    }
  }

  &[data-active] {

    &,
    &:hover {
      background-color: #0560be;
      border-radius: 12px;
      color: white;

      & .optionsIcon {
        color: white;
        border-radius: 12px;
      }

      & img.optionsIcon {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.optionsIcon {
  margin-right: 4%;
  color: #506176;
  width: 24px;
}

.header-content-main-pages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  width: 100%;
  padding: 0;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}