.filter .mantine-Input-input {
    border: 2px solid #E0E0E0;
    border-radius: 12px;
    color: #506176;
    height: 44px;
}

.filter .mantine-DatePickerInput-input {
    border: 2px solid #E0E0E0;
    border-radius: 12px;
    color: #506176;
}

.filter .mantine-PillGroup-group {
    padding-top: 5px;

}

.rowsPage {
    align-items: baseline;
}

.rowsPage .mantine-Select-root {
    margin-top: 0.6rem;
}

.multi-select-equipment .mantine-MultiSelect-pill {
    display: none;
}

.multi-select-network .mantine-MultiSelect-pill {
    display: none;
}